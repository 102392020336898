<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="bgc-white bd bdrs-3 p-20 mB-20">
          <h2 class="c-grey-900 mB-20" style="display: inline-block"> اسئلة {{subject}}
          </h2>
          <loading v-if="loading" />
          <div v-if="!loading" class="row gap-20">
            <div class="col-md-3">
              <div class="layers bd bgc-white p-20">
                <div class="layer w-100 mB-10">
                  <h6 class="lh-1">مجموع الاسئلة</h6>
                </div>
                <div class="layer w-100">
                  <div class="peers ai-sb fxw-nw">
                    <div class="peer peer-greed">
                      <span id="sparklinedash3">
                      </span>
                    </div>
                    <div class="peer">
                      <span class="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-blue-50 c-blue-500">{{questions.length +' '+'سؤال'}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="layers bd bgc-white p-20">
                <div class="layer w-100 mB-10">
                  <h6 class="lh-1">الاسئلة المصححة</h6>
                </div>
                <div class="layer w-100">
                  <div class="peers ai-sb fxw-nw">
                    <div class="peer peer-greed">
                      <span id="sparklinedash">
                      </span>
                    </div>
                    <div class="peer">
                      <span class="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-green-50 c-green-500">{{getAllTestedQuestions() +' '+'سؤال'}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="layers bd bgc-white p-20">
                <div class="layer w-100 mB-10">
                  <h6 class="lh-1">الاسئلة المعلمة</h6>
                </div>
                <div class="layer w-100">
                  <div class="peers ai-sb fxw-nw">
                    <div class="peer peer-greed">
                      <span id="sparklinedash4">
                      </span>
                    </div>
                    <div class="peer">
                      <span class="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-red-50 c-red-500">{{getAllMarkedQuestions() +' '+'سؤال'}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="layers bd bgc-white p-20">
                <div class="layer w-100 mB-10">
                  <h6 class="lh-1">الاسئلة المتبقية</h6>
                </div>
                <div class="layer w-100">
                  <div class="peers ai-sb fxw-nw">
                    <div class="peer peer-greed">
                      <span id="sparklinedash2">
                      </span>
                    </div>
                    <div class="peer">
                      <span class="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-grey-50 c-black-500">{{questions.length - (getAllMarkedQuestions() + getAllTestedQuestions())  +' '+'سؤال'}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading " class="row">
      <div class="col-md-12">
        <div class="bgc-white bd bdrs-3 p-20">
          <div class="row">
            <div class="col-sm-3">
                  <select id="lessonSelect" v-model="currentLesson" style="height: 40px;" @change="handleLessonChange" >
                    <option v-for="(lesson, index) in lessons" :key="index" :value="index">
                      {{ lesson[0].lesson.name +'('+lesson.length+')' }}
                    </option>
                  </select>
                </div>
            <div class="col-sm-1">
              <button type="button" class="btn btn-success btn-lg" @click="test">
                <i class="ti-check"></i>{{ getTestedQuestions() }}
              </button>
            </div>
            <div class="col-sm-1">
              <button type="button" class="btn btn-danger btn-lg" @click="mark">
                <i class="ti-flag "></i>{{ getMarkedQuestions() }}
              </button>
            </div>
            <div class="col-sm-4">
              <div v-if="lessons[currentLesson][currentIndex -1].marked" class="alert alert-danger" style="height: 40px;" role="alert"> معلم </div>
              <div v-if="lessons[currentLesson][currentIndex -1].tested" class="alert alert-success" style="height: 40px;" role="alert"> صحيح </div>
            </div>
            <div class="col-sm-3">
              <div class="row">
                <div class="col-sm-2" style="padding: 0;">
                  <button type="button" class="btn btn-primary btn-lg" style="margin: 0;" @click="next" :disabled="lessons[currentLesson].length == currentIndex">
                    <i class=" ti-angle-right"></i>
                  </button>
                </div>
                <div class="col-sm-8">
                  <input min="1" class="form-control" ref="textInput" :value="currentIndex" style="height: 40px;display: inline-block  ;margin: auto;"@keyup.enter="handleEnterKey" @blur="handleEnterKey"/>
                </div>
                <div class="col-sm-2" style="padding: 0;">
                  <button type="button" class="btn btn-primary btn-lg" style="margin: 0;" @click="prev" :disabled="currentIndex ==1" >
                    <i class=" ti-angle-left"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="bgc-white bd bdrs-3 p-20">
          <div class="row">
            <div class="col-sm-4">
              <h4 class="c-grey-700 mB-20" style="display: inline-block"> {{ 'وحدة: '+lessons[currentLesson][currentIndex -1].chapter.name }}
              </h4>
            </div>
            <div class="col-sm-4">
              <h4 class="c-grey-700 mB-20" style="display: inline-block"> {{ 'الدرس: '+lessons[currentLesson][currentIndex -1].lesson.name }}
              </h4>
            </div>
            <div class="col-sm-4">
              <h4 class="c-grey-700 mB-20" style="display: inline-block"> {{ 'رقم السؤال في الدرس: '+(currentIndex) }}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <div class="bgc-white bd bdrs-3 p-20">
          <div class="row">
            <div class="col-sm-9">
              <td v-if="checkForDollarSigns(lessons[currentLesson][currentIndex -1].text.body)">
                <h2 class="c-grey-900 mB-20" style="display: inline">
                  <span v-for="text in processedText(lessons[currentLesson][currentIndex -1].text.body)">
                    <vue-mathjax v-if="!text.isText" style="display: inline;" :formula="text.text" safe="" id="math-formula" :options="customOptions"></vue-mathjax>
                  <span v-else>{{ text.text }}</span>
                  </span>
                </h2>
                </td>
                <td v-else>
                  <h2 class="c-grey-900 mB-20" style="display: inline-block">{{ lessons[currentLesson][currentIndex -1].text.body }}</h2>
                </td>
            </div>
            <div class="col-sm-1">
              <button type="button" class="btn btn-warning btn-lg" @click="editQuestion(lessons[currentLesson][currentIndex -1])">
                <i class=" ti-pencil"></i>
              </button>
            </div>
            <div class="col-sm-1">
              <button type="button" class="btn btn-primary btn-lg" @click="addQuestion()">
                <i class=" ti-plus  "></i>
              </button>
            </div>
            <div class="col-sm-1">
              <button type="button" class="btn btn-danger btn-lg" @click="showPopup()">
                <i class=" ti-trash"></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3" v-for="(answer, index) in lessons[currentLesson][currentIndex -1].answers" :key="index">
              <div v-if='answer.is_true' class="alert alert-success" style="height: 40px;" role="alert">
                <vue-mathjax v-if="checkForDollarSigns(answer.answer)" style="display: inline;" :formula="answer.answer" safe="" id="math-formula" :options="customOptions"></vue-mathjax>
                          <div v-else>{{ answer.answer }}</div>
              </div>
              <div v-else class="alert alert-secondary" style="height: 40px;" role="alert">
                <vue-mathjax v-if="checkForDollarSigns(answer.answer)" style="display: inline;" :formula="answer.answer" safe="" id="math-formula" :options="customOptions"></vue-mathjax>
                          <div v-else>{{ answer.answer }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div  class="modal-header" style="direction: rtl;">
      <h5 class="modal-title text-right" id="exampleModalLongTitle">كلمة السر</h5>
      <button @click="exit" type="button" class="close" data-dismiss="modal" aria-label="Close" style="position: absolute; left: 15px; top: 15px;">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
      <div class="modal-body">
        <input
                    class="form-control"
                    v-model="pass"
                    placeholder="كلمة السر"
                  />
      </div>
      <div v-if="!deleteLoading" class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="exit">إلغاء</button>
        <button type="button" class="btn btn-danger" @click="deleteQuestion">حذف</button>
      </div>
      <div v-else class="modal-footer">
        <loading />
      </div>
      
    </div>
  </div>
</div>
  </div>
</template>
<script>
  export default {
    props: {
      id: {
        type: String,
        required: true
      },
      subject: {
        type: String,
        required: true
      }
    },
    mounted() {
    // Attach event listener for keydown event on the whole document
    document.addEventListener('keydown', this.handleArrowKey);
  },
  beforeDestroy() {
    // Remove event listener when component is destroyed
    document.removeEventListener('keydown', this.handleArrowKey);
  },
    data() {
      return {
        customOptions: {
        displayAlign: 'right',
      },
        pass:'',
        deleteLoading:false,
        loading: true,
        questions: [],
        markedQuestions: [],
        testedQuestions: [],
        lessons: [],
        currentIndex: 1,
        currentLesson: 0,
      }
    },
    created() {
      this.getSubjectQuestions();
    },
    methods: {
      processedText(text) {
    const delimiter = '\\$\\$'; // RegExp delimiter for $$ in JavaScript
    const regExp = new RegExp(delimiter, 'g');
    const parts = text.split(regExp);

    const result = [];

    // Check if the first part starts with $$
    let isText = true;

    parts.forEach(part => {
        const trimmedPart = part.trim();
        if (trimmedPart.length > 0) {
            if (isText) {
                result.push({
                    text: trimmedPart,
                    isText: true
                });
            } else {
                result.push({
                    text: `$$${trimmedPart}$$`, // Add $$ back to the text
                    isText: false
                });
            }
        }
        // Flip isText status
        isText = !isText;
    });

    console.log(result);

    return result;
},
  
      checkForDollarSigns(text) {
      return text.includes('$$');
    },
      exit() {
        document.addEventListener('keydown', this.handleArrowKey);
        this.pass = "";
      },
      showPopup() {
        document.removeEventListener('keydown', this.handleArrowKey);
        $('#exampleModal').modal('show');
      },
      handleArrowKey(event) {
      // Check if the pressed key is the left or right arrow key
      if (event.keyCode === 37 || event.key === 'ArrowLeft') {
        // Call your function for left arrow key press
        this.yourFunctionLeft();
      } else if (event.keyCode === 39 || event.key === 'ArrowRight') {
        // Call your function for right arrow key press
        this.yourFunctionRight();
      }
    },
    yourFunctionLeft() {
      // Your function implementation for left arrow key press goes here
      if(this.currentIndex !=1) {
        this.prev();
      }
    },
    yourFunctionRight() {
      // Your function implementation for right arrow key press goes here
      if(this.lessons[this.currentLesson].length != this.currentIndex) {
        this.next();
      }
    },
      getAllMarkedQuestions() {
        var sum = 0;
        this.questions.forEach(function(question) {
          if(question.marked) {
            sum++;
          }
        });
        return sum;
      },
      getAllTestedQuestions() {
        var sum = 0;
        this.questions.forEach(function(question) {
          if(question.tested) {
            sum++;
          }
        });
        return sum;
      },
      getMarkedQuestions() {
        var sum = 0;
        this.lessons[this.currentLesson].forEach(function(question) {
            if(question.marked) {
              sum++;
            }
          });
        return sum;
      },
      getTestedQuestions() {
        var sum = 0;
        this.lessons[this.currentLesson].forEach(function(question) {
            if(question.tested) {
              sum++;
            }
          });
        return sum;
      },
      mark() {
        this.lessons[this.currentLesson][this.currentIndex - 1].marked = true;
        this.lessons[this.currentLesson][this.currentIndex - 1].tested = false;
        let temp = this.currentIndex;
        this.currentIndex = 0;
        this.currentIndex = temp;
        console.log('hello')
      },
      test() {
        this.lessons[this.currentLesson][this.currentIndex - 1].marked = false;
        this.lessons[this.currentLesson][this.currentIndex - 1].tested = true;
        let temp = this.currentIndex;
        this.currentIndex = 0;
        this.currentIndex = temp;
      },
      handleEnterKey() {
        const inputValue = this.$refs.textInput.value;

        if (inputValue == '' | isNaN(inputValue) | inputValue == 0 | inputValue > this.lessons[this.currentLesson].length) {
        let temp = this.currentIndex;
        this.currentIndex = 0;
        this.currentIndex=temp;
        return;
      }
      this.currentIndex = inputValue;
      },
      next() {
        ++this.currentIndex;
      },
      prev() {
        --this.currentIndex;
      },
      handleLessonChange() {
        this.currentIndex =1;
      },
      editQuestion(question) {
        document.removeEventListener('keydown', this.handleArrowKey);
        const panel1Handle = this.$showPanel({
          component: () => import("../subjects/chapters/lessons/questions/EditQuestion.vue"),
          openOn: "right",
          width: 700,
          disableBgClick: true,
          props: {
            question: question,
            reload: this.getSubjectQuestions,
          },
        });
        panel1Handle.promise.then((result) => {
          document.addEventListener('keydown', this.handleArrowKey);
        });
      },
      addQuestion: function () {
        document.removeEventListener('keydown', this.handleArrowKey);
      const panel1Handle = this.$showPanel({
        component: () => import("../subjects/chapters/lessons/questions/AddQuestion.vue"),
        openOn: "right",
        width: 700,
        disableBgClick: true,
        props: {
          reload: 
              this.getSubjectQuestions,
          lessonId: this.lessons[this.currentLesson][0].lesson_id+'',
        },
      });
      panel1Handle.promise.then((result) => {
          document.addEventListener('keydown', this.handleArrowKey);
        });
    },
      getSubjectQuestions: function() {
        this.loading = true;
        this.$http.get("https://yalla-emtihan.com/backend/questions/print", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          params: {
            'subject_id': this.id
          },
        }).then(function(data) {
            this.loading = false;
            switch (data.status) {
              case 200:
                if(this.lessons.length == 0) {
                  this.questions = data.body.subject.questions
                this.lessons = this.questions.reduce((acc, question) => {
                  const lessonId = question.lesson_id;
                  if (!acc[lessonId]) {
                    acc[lessonId] = []; // Initialize an array if not already present
                  }
                  acc[lessonId].push(question);
                  return acc;
                }, []).filter(Boolean);
                }
                else {
                  var temp = Array.from(this.lessons);
                  this.questions = data.body.subject.questions;
                  this.lessons = this.questions.reduce((acc, question) => {
                  const lessonId = question.lesson_id;
                  if (!acc[lessonId]) {
                    acc[lessonId] = []; // Initialize an array if not already present
                  }
                  acc[lessonId].push(question);
                  return acc;
                }, []).filter(Boolean);

                this.lessons.forEach(function(lesson, index) {
                  lesson.forEach( function(question,index2) {
                    if(Array.isArray(temp[index]) && temp[index].length > index2) {
                      question.marked = temp[index][index2].marked? true : false;
                    question.tested = temp[index][index2].tested? true : false;
                    }
                   
                  });
                })
                }
                break;
            }
          },
          (err) => {
            this.loading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
                break;
            }
          });
      },
      deleteQuestion: function () {
        this.deleteLoading = true;
      let headers = {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      };
      this.$http
        .patch(
          "https://yalla-emtihan.com/backend/questions/deleteQuestion",
          {
            question_id: this.lessons[this.currentLesson][this.currentIndex - 1].id,
            pass: this.pass
          },
          { headers }
        )

        .then(
          function (data) {
            this.deleteLoading = false;
            switch (data.status) {
              case 200:
              $('#exampleModal').modal('hide');
              this.exit();
                this.success(this.$t("success"));

                var id = this.lessons[this.currentLesson][this.currentIndex - 1].id;
                this.questions = this.questions.filter(item => item.id !==id );
                this.lessons[this.currentLesson] = this.lessons[this.currentLesson].filter((item => item.id !== id));
                --this.currentIndex; 
                break;
            } 
          },
          (err) => {
            this.deleteLoading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
            }
          }
        );
    },
      error: function(message) {
        this.$toast.error(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      warning: function(message) {
        this.$toast.warning(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      success: function(message) {
        this.$toast.success(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
    },
  };
</script>
<style>
#math-formula .MathJax_Display {
    display: inline !important;
    direction: rtl !important
}
#math-formula .MathJax_Preview {
    display: inline !important;
}
</style>